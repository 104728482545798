import { post } from "@/api"
import Alerter from "@/common/alerter"
import { useCallback, useState } from "react"

export const useCSVUpload = ({ openingId, onLoad, onError }) => {
  const [isLoading, setIsLoading] = useState(false)

  const request = useCallback(
    async ({ school_id, file }) => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("school_id", parseInt(school_id, 10))
      if (openingId) formData.append("opening_id", parseInt(openingId, 10))
      try {
        await post(
          "students/import_csv",
          formData,
          onLoad,
          null, // onError
          null, // onFinally
          {
            // options
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        Alerter.success("File successfully uploaded")
      } catch (e) {
        // Only show the error message from the server response (inside CSV Import modal)
        if (e?.response?.data) {
          onError(e.response.data)
        } else {
          // Fallback to generic error message only if we don't have response data
          Alerter.error(e.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [openingId, onLoad, onError]
  )

  return [isLoading, request]
}
