import CloseIcon from "@mui/icons-material/Close"
import { Dialog, IconButton } from "@mui/material"
import React from "react"
import { Button } from "react-bootstrap"
import { generatePath, useNavigate } from "react-router-dom"
import { RouteNames, RoutesPatterns } from "../../configurations/routing"

export const LessonspacePreviewModal = ({ show, onClose, onPreview, scheduleId }) => {
  const navigate = useNavigate()

  const handleCurriculumClick = e => {
    e.preventDefault()
    const curriculumPath = generatePath(RoutesPatterns[RouteNames.Curriculum], { scheduleId })
    navigate(curriculumPath)
    onClose()
  }

  return (
    <Dialog open={show} onClose={onClose} fullWidth>
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className="mb-0">Lessonspace preview mode</h4>
          <IconButton aria-label="close" onClick={onClose} size="small" sx={{ color: "text.primary" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <p>
          You are entering a preview mode of Lessonspace. Resources are only available during live Lessonspace
          sessions.&nbsp;
          <strong>
            To access resources outside of a live session, visit the&nbsp;
            <a href="#" onClick={handleCurriculumClick} className="text-primary">
              Curriculum
            </a>
            &nbsp;tab.
          </strong>
        </p>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="outline-primary" onClick={onClose} className="mr-2">
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onPreview(scheduleId)}>
            Preview Lessonspace
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
