export const WEEKDAYS = [
  { value: "sunday", label: "Sunday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
]

export const SHORT_WEEKDAYS = [
  { value: "sun", label: "Sunday" },
  { value: "mon", label: "Monday" },
  { value: "tue", label: "Tuesday" },
  { value: "wed", label: "Wednesday" },
  { value: "thu", label: "Thursday" },
  { value: "fri", label: "Friday" },
  { value: "sat", label: "Saturday" },
]

export const DAYPERIODS = [
  { value: "m", label: "Morning", shortLabel: "am" },
  { value: "d", label: "Noon", shortLabel: "noon" },
  { value: "e", label: "Evening", shortLabel: "pm" },
]

export const EXECUTIVE_FUNCTION_DOMAIN = "executive_function"

export const BREAK_REASONS = [
  "Student no longer needs tutoring",
  "Scheduling issue",
  "Location issue",
  "Qualification issue",
  "Personality fit",
]

export const SPECIAL_TREATMENT_REQUIREMENTS = {
  adhd: "If you feel you can support students with ADHD, please add the appropriate Executive Function program in the section above. If you have any special training or attended a conference on students who have ADHD, please include your certificate below.",
  dyslexia:
    "Please note evidence of training in multi-sensory phonics curriculum is REQUIRED for approval to support students with dyslexia. " +
    "You can upload a training certificate or a screenshot of evidence you attended training in the section below ",
  // dysgraphia: "You need to upload dysgraphia certificate",
  // language_based_learning_disability: "You need to upload language_based_learning_disability certificate",
  // dyscalculia: "You need to upload dyscalculia certificate",
}

export const INTERVIEW_DURATION = 30
export const SESSION_DURATIONS = Array(12)
  .fill(1)
  .map((value, idx) => (value + idx) * 15)
SESSION_DURATIONS.splice(2, 0, 40)

export const UPCOMING_INTERVIEW = "upcoming"
export const REQUESTED_INTERVIEW = "waiting_for_accept"
export const TIME_SUGGESTED_INTERVIEW = "new_time_suggested"
export const COMPLETED_INTERVIEW = "completed"
export const CANCELLED_INTERVIEW = "canceled"
export const ALL_PAST_INTERVIEW = "all_past"

export const PENDING_REQUEST = "pending"
export const DECLINED_REQUEST = "declined"
export const CONFIRMED_REQUEST = "confirmed"

export const MEDIA_SM_BREAKPOINT = 576
export const MEDIA_MD_BREAKPOINT = 768
export const MEDIA_LG_BREAKPOINT = 992
export const MEDIA_XL_BREAKPOINT = 1200
export const MEDIA_XXL_BREAKPOINT = 1390

export const INTERVIEW_CALENDAR_MIN_TIME = "07:00:00"
export const INTERVIEW_CALENDAR_MAX_TIME = "22:00:00"

export const DEGREE_ABBREVIATION = {
  doctorate: "PhD",
  masters_degree: "MS",
  bachelors_degree: "BS",
  associate_degree: "AS",
}

export const YES_NO_OPTIONS = ["Yes", "No"]
export const RECEIVE_SESSIONS_OPTIONS = {
  YES: "Yes",
  NO: "No",
}

export const ORGANIZATION_LIST = [
  {
    title: "All Organizations",
    value: "all",
  },
  {
    title: "Braintrust",
    value: "braintrust",
  },
  {
    title: "Smarten Up",
    value: "1",
  },
  {
    title: "Partnership",
    value: "2",
  },
]

export const ALL_TUTORS_LIST = [
  {
    id: "all",
    title: "All tutors",
  },
]
