import { merge } from "lodash"
import apiClient from "../client"
import { windowObj as window } from "../utils/apiUtils"
import { showErrorMessage } from "./errorHandlers"

export const request = requestConfig => apiClient.request(requestConfig)

/**
 * Handles API requests with various configurations and callbacks.
 *
 * @param {Object} config - The configuration object for the request.
 * @param {string} config.method - The HTTP method for the request (e.g., 'get', 'post', 'put', 'delete').
 * @param {string} config.url - The URL for the API endpoint.
 * @param {Object} [config.payload] - The payload data to be sent with the request.
 * @param {Object} [config.params] - The query parameters for the request.
 * @param {string} [config.redirectUrl] - The URL to redirect to after a successful request.
 * @param {Function} [config.onSuccess] - Callback function to be executed on successful request.
 * @param {Function} [config.onError] - Callback function to be executed on request error.
 * @param {Function} [config.onFinally] - Callback function to be executed after request completion (success or error).
 * @param {Object} [config.options={}] - Additional options to be merged with apiClient defaults.
 * @returns {Promise} A promise that resolves with the API response or rejects with an error.
 */
const handleRequest = ({
  method,
  url,
  payload,
  params,
  redirectUrl,
  onSuccess,
  onError,
  onFinally,
  options = {},
} = {}) => {
  const makeRequest = () => {
    const mergedOptions = merge({}, apiClient.defaults, options, { params })
    return method === "get" || method === "delete"
      ? apiClient[method](url, mergedOptions)
      : apiClient[method](url, payload, mergedOptions)
  }

  const handleSuccess = response => {
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else if (onSuccess) {
      onSuccess(response)
    }
    return response
  }

  const handleError = error => {
    if (onError) {
      onError(error)
    } else {
      showErrorMessage(error)
    }
    return Promise.reject(error)
  }

  return makeRequest().then(handleSuccess).catch(handleError).finally(onFinally)
}

const get = (url, params, onSuccess, onError) => handleRequest({ method: "get", url, params, onSuccess, onError })

const getAndRedirect = (url, redirectUrl, params, onSuccess, onError) =>
  handleRequest({ method: "get", url, params, redirectUrl, onSuccess, onError })

const postAndRedirect = (url, redirectUrl, payload, onError) =>
  handleRequest({ method: "post", url, payload, redirectUrl, onError })

const post = (url, payload, onSuccess, onError, onFinally, options) =>
  handleRequest({ method: "post", url, payload, onSuccess, onError, onFinally, options })

const patch = (url, payload, onSuccess, onError, onFinally, options) =>
  handleRequest({ method: "patch", url, payload, onSuccess, onError, onFinally, options })

const patchAndRedirect = (url, redirectUrl, payload, onError) =>
  handleRequest({ method: "patch", url, payload, redirectUrl, onError })

const putAndRedirect = (url, redirectUrl, payload, onError) =>
  handleRequest({ method: "put", url, payload, redirectUrl, onError })

const put = (url, payload, onSuccess, onError, onFinally) =>
  handleRequest({ method: "put", url, payload, onSuccess, onError, onFinally })

const deleteRequest = (url, params, onSuccess, onError, onFinally) =>
  handleRequest({ method: "delete", url, params, onSuccess, onError, onFinally })

export { get, getAndRedirect, post, postAndRedirect, patch, patchAndRedirect, put, putAndRedirect, deleteRequest }
