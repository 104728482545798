import Alerter from "@/common/alerter"
import { RECEIVE_SESSIONS_OPTIONS, YES_NO_OPTIONS } from "@/constants"
import { className } from "@/helpers/className"
import useViewport from "@/hooks/useViewport"
import * as EmailValidator from "email-validator"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"

const CreateSchoolAdminPageWrapper = ({ children, title, titleAffix }) => {
  const { isMobileViewport } = useViewport()
  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row>
        <Col className="p-0">
          <div className="container users-navbar_container -white justify-content-between">
            <h2 className="users-navbar_title m-0">{title}</h2>
            {titleAffix}
          </div>
          <div className="container mt-4 px-0">
            <Row className="mx-n2 px-2 mb-4">
              <Col as="main" xs={24}>
                <Card className={className(isMobileViewport ? "px-3 py-4" : "px-4 py-5")}>{children}</Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const CreateOrUpdateForm = ({ title, onSubmit, initialValues }) => {
  const schoolAdminInit = initialValues?.schoolAdmin
  const receiveSessionsInit = initialValues?.receiveSessions
  const school = schoolAdminInit?.school_id

  const [firstName, setFirstName] = useState(schoolAdminInit?.first_name)
  const [lastName, setLastName] = useState(schoolAdminInit?.last_name)
  const [email, setEmail] = useState(schoolAdminInit?.email)
  const [receiveSessions, setReceiveSessions] = useState(
    receiveSessionsInit ? RECEIVE_SESSIONS_OPTIONS.YES : RECEIVE_SESSIONS_OPTIONS.NO
  )
  const [emailValid, setValidateEmail] = useState(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleOnChange = e => {
    e.preventDefault()
    const emailValue = e.target.value
    setEmail(emailValue)
    const isValid = EmailValidator.validate(emailValue)
    setValidateEmail(isValid)
  }

  const handleOnSubmit = useCallback(
    async e => {
      e.preventDefault()
      try {
        setIsSubmitting(true)
        await onSubmit(e, schoolAdminInit?.id, email, firstName, lastName, receiveSessions, school)
      } catch (error) {
        Alerter.error(error.response.data.errors)
      } finally {
        setIsSubmitting(false)
      }
    },
    [onSubmit, schoolAdminInit?.id, email, firstName, lastName, receiveSessions, school]
  )

  const isSubmitDisabled = useMemo(
    () => !email || !firstName || !lastName || !emailValid || isSubmitting,
    [email, firstName, lastName, emailValid, isSubmitting]
  )

  const formRef = useRef(null)

  useEffect(() => {
    const firstInput = document.querySelector("form input")
    firstInput?.focus()
  }, [formRef])

  useEffect(() => {
    if (email) {
      setValidateEmail(EmailValidator.validate(email))
    }
  }, [email])

  const focusStyles = {
    outline: "2px solid #3b82f6",
    outlineOffset: "2px",
    boxShadow: "0 0 0 3px rgba(59, 130, 246, 0.5)",
  }

  const inputStyles = {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    boxSizing: "border-box",
  }

  const handleRadioKeyDown = e => {
    const radioButtons = Array.from(document.querySelectorAll('input[type="radio"][name="receiveSessions"]'))
    const currentIndex = radioButtons.indexOf(e.target)

    switch (e.key) {
      case "ArrowRight":
      case "ArrowDown":
        e.preventDefault()
        radioButtons[(currentIndex + 1) % radioButtons.length].focus()
        break
      case "ArrowLeft":
      case "ArrowUp":
        e.preventDefault()
        radioButtons[(currentIndex - 1 + radioButtons.length) % radioButtons.length].focus()
        break
      case " ":
      case "Enter":
        e.preventDefault()
        e.target.click()
        break
      default:
        break
    }
  }

  const handleFormKeyDown = e => {
    if (e.key === "Enter" && e.target.tagName !== "TEXTAREA") {
      handleOnSubmit(e)
    }
  }

  return (
    <CreateSchoolAdminPageWrapper title={title}>
      <Row data-testid="form-container">
        <Col xs={24} lg={20} xl={14}>
          <form
            ref={formRef}
            onSubmit={handleOnSubmit}
            onKeyDown={handleFormKeyDown}
            data-testid="create-or-update-form"
          >
            <div className="mb-4 form-group" data-testid="email-group">
              <div className="px-2 form-row">
                <label
                  htmlFor="email"
                  className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24"
                  data-testid="email-label"
                >
                  Email
                </label>
                <Form.Control
                  id="email"
                  type="email"
                  style={{ ...inputStyles, ":focus": focusStyles }}
                  className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24 control-label"
                  defaultValue={email}
                  isValid={emailValid}
                  isInvalid={emailValid === false && email !== ""}
                  required
                  onChange={e => handleOnChange(e)}
                  data-testid="email-input"
                  aria-describedby="email-error"
                />
                <Form.Control.Feedback type="invalid" id="email-error" data-testid="email-error">
                  Please enter a valid email address
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="mb-4 form-group" data-testid="first-name-group">
              <div className="px-2 form-row">
                <label
                  htmlFor="first-name"
                  className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24"
                >
                  First Name
                </label>
                <Form.Control
                  id="first-name"
                  type="text"
                  style={{ ...inputStyles, ":focus": focusStyles }}
                  className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24"
                  defaultValue={firstName}
                  required
                  minLength={2}
                  maxLength={50}
                  onChange={e => setFirstName(e.target.value)}
                  data-testid="first-name-input"
                  aria-describedby="first-name-error"
                />
                <Form.Control.Feedback type="invalid" id="first-name-error" data-testid="first-name-error">
                  Please enter a valid first name (2-50 characters)
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="mb-4 form-group">
              <div className="px-2 form-row">
                <label
                  htmlFor="last-name"
                  className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24"
                >
                  Last Name
                </label>
                <Form.Control
                  id="last-name"
                  type="text"
                  required
                  style={{ ...inputStyles, ":focus": focusStyles }}
                  className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24"
                  defaultValue={lastName}
                  onChange={e => {
                    setLastName(e.target.value)
                  }}
                  aria-describedby="last-name-error"
                  data-testid="last-name-input"
                />
              </div>
              <Form.Control.Feedback type="invalid" id="last-name-error" data-testid="last-name-error">
                Please enter a valid last name
              </Form.Control.Feedback>
            </div>

            <fieldset className="px-2 form-row" data-testid="receive-sessions-group">
              <legend
                className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24"
                data-testid="receive-sessions-legend"
              >
                Receive Student Session Reports
              </legend>
              <div className="d-flex" data-testid="receive-sessions-options">
                {YES_NO_OPTIONS.map(el => (
                  <Form.Check
                    key={el}
                    name="receiveSessions"
                    type="radio"
                    className="mr-3"
                    id={`receive-sessions-${el.toLowerCase()}`}
                    onChange={() => setReceiveSessions(el)}
                    checked={el === receiveSessions}
                    defaultValue={el}
                    label={el}
                    custom
                    data-testid={`receive-sessions-${el.toLowerCase()}`}
                    aria-label={`Receive sessions ${el}`}
                    role="radio"
                    tabIndex={0}
                    onKeyDown={handleRadioKeyDown}
                  />
                ))}
              </div>
            </fieldset>
            <div className="mt-4 form-group" data-testid="submit-group">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitDisabled}
                onClick={handleOnSubmit}
                data-testid="submit-button"
                aria-busy={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Saving...</span>
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </CreateSchoolAdminPageWrapper>
  )
}

export default CreateOrUpdateForm
