import CloseIcon from "@mui/icons-material/Close"
import { Dialog, IconButton } from "@mui/material"
import React, { useContext, useState } from "react"
import { Button } from "react-bootstrap"
import { Field, Form } from "react-final-form"
import FormRow from "../blocks/FormRow"
import SelectWithSearchInput from "../../common/LookupWithSearchInput"
import useFetchScheduleProgram from "../opening_module/pages/curriculum/hooks/useFetchScheduleProgram"
import { colProps, FieldNames, labelProps } from "../schedule_module/forms"
import LookupFieldLoader from "../schedule_module/forms/session_details/LookupFieldLoader"
import { DataContext } from "../../contexts"
import Alerter from "../../common/alerter"
import apiClient from "@/api"

export const NewAssignmentModal = ({ show, onClose, onJoinClassClick, setUpdatedAssignmentId, session }) => {
  const { scheduleId, schedule } = useContext(DataContext)
  const { programLevels, loading: loadingProgram } = useFetchScheduleProgram({ scheduleId: scheduleId })
  const [attachingAssignment, setAttachingAssignment] = useState(false)
  const [level, setLevel] = useState("")
  const [lesson, setLesson] = useState("")
  const [lessonsListByLevel, setLessonListByLevel] = useState([])

  const programLevelsList = (programLevels || [])?.map(programLevel => ({
    title: programLevel.name,
    value: programLevel.id,
  }))

  const submit = async () => {
    setAttachingAssignment(true)
    let assignment_type = session?.assignment_type
    if (lesson && level) {
      assignment_type = "Lesson"
    }

    const sessionToUpdate = {
      assignment_id: lesson,
      assignment_type,
      duration: schedule.max_duration,
      meeting_format: schedule.meeting_format,
      ids: [session.id],
      meeting_info: schedule.meeting_info,
      started_at: session.scheduled_at,
    }

    try {
      await apiClient.patch(`/api/group_sessions`, sessionToUpdate)
      setUpdatedAssignmentId(level)
      setAttachingAssignment(false)
      onJoinClassClick(_, true)
      onClose()
    } catch (error) {
      Alerter.error("An error occurred while assigning the assignment")
      setAttachingAssignment(false)
      onJoinClassClick(_, false)
      onClose()
    }
  }

  const saveIsDisabled = attachingAssignment || !level || !lesson

  return (
    <Dialog open={show} onClose={onClose} fullWidth>
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className="mb-0">Join Session</h4>
          <IconButton aria-label="close" onClick={onClose} size="small" sx={{ color: "text.primary" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <p>Please add a level and lesson to this session before continuing</p>
        <div>
          <Form onSubmit={submit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {loadingProgram ? (
                  <LookupFieldLoader />
                ) : (
                  <Field type="text" name={FieldNames.Level}>
                    {() => (
                      <FormRow
                        label="Level"
                        RowProps={{ className: "d-flex mb-0 flex-column" }}
                        ColProps={{ ...{ colProps, md: 22 }, className: "d-flex mb-0 flex-column" }}
                        LabelProps={labelProps}
                        className="mb-2"
                      >
                        <SelectWithSearchInput
                          id="level"
                          defaultValue={""}
                          value={level}
                          handleOnChangeSelect={event => {
                            const levelId = event.target.value
                            const programLevel = programLevels?.find(prLevel => prLevel.id === levelId)
                            const lessonsList = programLevel?.lessons.map(lesson => ({
                              title: lesson.name,
                              value: lesson.id,
                            }))

                            setLevel(levelId)
                            setLessonListByLevel(lessonsList)
                            setLesson(null)
                          }}
                          style={{
                            borderRadius: "10px",
                            maxWidth: "100%",
                          }}
                          inputStyle={{
                            minHeight: "0px !important",
                          }}
                          menuListStyle={{
                            overflowX: "scroll",
                            maxHeight: "300px",
                          }}
                          name={FieldNames.Level}
                          optionsList={programLevelsList}
                          placeholder="Select Level"
                          showSearchBar={false}
                          searchText={""}
                        />
                      </FormRow>
                    )}
                  </Field>
                )}

                {loadingProgram ? (
                  <LookupFieldLoader />
                ) : (
                  <Field type="text" name={FieldNames.Lesson}>
                    {() => (
                      <FormRow
                        label="Lesson"
                        RowProps={{ className: "d-flex mb-2 mt-4 flex-column" }}
                        ColProps={{ ...{ colProps, md: 22 }, className: "d-flex mb-0 d-flex flex-column" }}
                        LabelProps={labelProps}
                      >
                        <SelectWithSearchInput
                          id="lesson"
                          defaultValue={""}
                          value={lesson}
                          disabled={Boolean(!level)}
                          handleOnChangeSelect={event => {
                            setLesson(event.target.value)
                          }}
                          style={{
                            borderRadius: "10px",
                            maxWidth: "100%",
                          }}
                          inputStyle={{
                            minHeight: "0px !important",
                          }}
                          menuListStyle={{
                            overflowX: "scroll",
                            maxHeight: "300px",
                          }}
                          name={FieldNames.Lesson}
                          optionsList={lessonsListByLevel}
                          placeholder="Select Lesson"
                          showSearchBar={false}
                          searchText={""}
                        />
                      </FormRow>
                    )}
                  </Field>
                )}
                <div className="d-flex justify-content-end mt-4">
                  <Button
                    variant="outline-primary"
                    onClick={e => {
                      onJoinClassClick(e, true)
                      onClose()
                    }}
                    className="mr-2"
                  >
                    Skip
                  </Button>
                  <Button variant="primary" type="submit" disabled={saveIsDisabled}>
                    {attachingAssignment ? "Saving Assignment..." : "Save"}
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </Dialog>
  )
}
