import moment from "moment"
import React from "react"
import { Button } from "react-bootstrap"
import { START_ACTIVITY_TEXT } from "../constants"

export const OfflineSession = ({ nextSession, canStartNextSession }) => {
  const assignmentLink = nextSession?.assignment_link || null
  const canStartSession = canStartNextSession && assignmentLink

  const handleStartSession = (e) => {
    e.preventDefault();
    if (canStartSession) window.open(assignmentLink, "_blank")
  }

  
  return (
    <Button
      className={"btn btn-primary next-lesson-data-button"}
      size="sm"
      onClick={handleStartSession}
      disabled={!canStartNextSession || !assignmentLink}
    >
      {canStartNextSession ? "Start Activity" : `Can be started in ${moment(nextSession.scheduled_at).toNow(true)}`}
    </Button>
  )
}
