import { ReactComponent as Calendar } from "@/assets/images/calendar1.svg"
import { className } from "@/helpers/className"
import { formatDate, parseDate } from "@/helpers/dates"
import { buildId } from "@/helpers/forms"
import React, { forwardRef, useMemo } from "react"
import { Col, Form, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { useField } from "react-final-form"

/**
 * Default field names for the date range component
 */
const defaultNames = Object.freeze({
  start: "start_on",
  end: "end_on",
})

/**
 * Default configuration for the date fields
 */
const fieldConfig = { type: "time", defaultValue: new Date() }

/**
 * Custom input component for the date picker that adds a calendar icon
 * @component
 */
const DateInput = forwardRef(function DateInput(props, ref) {
  const fieldId = useMemo(buildId, [])
  return (
    <label htmlFor={fieldId} className="position-relative d-block mb-0">
      <Form.Control {...props} ref={ref} id={fieldId} className="v-2 -with-icon-24" />
      <div
        className={className(
          "icon-24-px input-side-icon-24",
          props.disabled ? "icon-gray-color cursor-default" : "icon-black-color cursor-text"
        )}
      >
        <Calendar />
      </div>
    </label>
  )
})

/**
 * A form component that renders a date range selector with start and end dates.
 * Handles date validation, formatting, and provides a consistent UI for date selection.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} [props.names] - Custom field names for start and end dates
 * @param {string} [props.className] - Additional CSS class for the root element
 * @param {string} [props.startPlaceholder] - Placeholder text for start date field
 * @param {string} [props.endPlaceholder] - Placeholder text for end date field
 * @param {boolean} [props.disabled] - Whether the fields are disabled
 * @param {Date} [props.minDate] - Minimum selectable date
 * @param {Date} [props.maxDate] - Maximum selectable date
 * @param {boolean} [props.startRequired] - Whether start date is required
 * @param {boolean} [props.endRequired] - Whether end date is required
 * @param {boolean} [props.showLabels=true] - Whether to show field labels
 */
export const DateRangeFields = ({
  names,
  className: rootClassName,
  startPlaceholder,
  endPlaceholder,
  disabled,
  minDate,
  maxDate,
  startRequired,
  endRequired,
  showLabels = true,
}) => {
  // Merge custom field names with defaults
  const { start, end } = { ...defaultNames, ...names }

  // Get field controls from react-final-form
  const startField = useField(start, fieldConfig)
  const endField = useField(end, fieldConfig)

  // Compute wrapper class name
  const wrapperClassName = useMemo(() => className("d-flex", "flex-nowrap", rootClassName), [rootClassName])

  return (
    <>
      <div className={wrapperClassName}>
        <div className="flex-grow-1 d-flex flex-column">
          {showLabels ? <span className="text-gray-chat field-subtitle">Start date {startRequired && "*"}</span> : null}
          <DatePicker
            name={start}
            disabled={disabled}
            placeholderText={`${startPlaceholder || "Date"}${startRequired ? "*" : ""}`}
            autoComplete="off"
            minDate={minDate}
            maxDate={parseDate(endField.input.value) || maxDate}
            selected={parseDate(startField.input.value)}
            onChange={date => startField.input.onChange(formatDate(date))}
            dateFormat="MM/dd/yyyy"
            customInput={<DateInput isInvalid={startField.meta.touched && startField.meta.error} />}
            required={startRequired}
          />
        </div>
        <div className="mx-2 mb-2 pb-1 align-self-end">—</div>
        <div className="flex-grow-1 d-flex flex-column">
          {showLabels ? <span className="text-gray-chat field-subtitle">End date {endRequired && "*"}</span> : null}
          <DatePicker
            name={end}
            disabled={disabled}
            minDate={parseDate(startField.input.value) || minDate}
            maxDate={maxDate}
            autoComplete="off"
            placeholderText={`${endPlaceholder || "Date"}${endRequired ? "*" : ""}`}
            selected={parseDate(endField.input.value)}
            onChange={date => endField.input.onChange(formatDate(date))}
            dateFormat="MM/dd/yyyy"
            customInput={<DateInput isInvalid={endField.meta.touched && endField.meta.error} />}
            required={endRequired}
          />
        </div>
      </div>
      {((startField.meta.touched && startField.meta.error) || (endField.meta.touched && endField.meta.error)) && (
        <Row>
          <Col>
            <Form.Control.Feedback type="invalid" className="d-block px-2">
              {startField.meta.error || endField.meta.error}
            </Form.Control.Feedback>
          </Col>
        </Row>
      )}
    </>
  )
}
