import { useContext, useEffect, useMemo, useState } from "react"
import { FormFieldNames } from "./constants"
import { MeetingFormats } from "../../../../constants"
import { formatDate } from "../../../../helpers/dates"
import { autocompleteEducatables } from "../../configurations/domain"
import { DataContext, QueryContext } from "../../../../contexts"
import { getSchoolsAutocomplete } from "../../../../domain"
import { defaultLocationValidation } from "../../../fields/location"

export const useCreateInitialValues = (schedules, schoolId, educatableId) => {
  const { getHookState } = useContext(QueryContext)
  const [initialSchoolComplete, setInitialSchoolComplete] = useState(false)
  const [initialEducatableComplete, setInitialEducatableComplete] = useState(false)
  const { request: requestSchool, loading: schoolLoading } = getHookState(getSchoolsAutocomplete())
  const { request: requestEducatable, loading: educatableLoading } = getHookState(autocompleteEducatables)
  const { schools, educatables: educatable } = useContext(DataContext)

  useEffect(() => {
    if (schoolId && requestSchool && !schoolLoading && !initialSchoolComplete) {
      requestSchool({ params: { id: schoolId }, onSuccess: () => setInitialSchoolComplete(true) })
    }
    if (educatableId && requestEducatable && !educatableLoading && !initialEducatableComplete) {
      requestEducatable({ params: { id: educatableId }, onSuccess: () => setInitialEducatableComplete(true) })
    }
  }, [
    schoolId,
    educatableId,
    requestSchool,
    requestEducatable,
    schoolLoading,
    educatableLoading,
    initialSchoolComplete,
    initialEducatableComplete,
  ])

  const initialValues = useMemo(() => {
    const [schedule = {}] = schedules
    let link = ""
    switch (schedule.meeting_format) {
      case MeetingFormats.Online:
        link = schedule.meeting_info
        break
    }
    return {
      [FormFieldNames.School]: schedule?.school ?? initialSchoolComplete ? schools?.[0] : void 0,
      [FormFieldNames.Educatable]: schedule?.educatable ?? initialEducatableComplete ? educatable?.[0] : void 0,
      [FormFieldNames.Subject]: schedule?.program,
      [FormFieldNames.StartOn]: schedule?.start_on ? new Date(schedule?.start_on) : null,
      [FormFieldNames.EndOn]: schedule?.end_on ? new Date(schedule?.end_on) : null,
      [FormFieldNames.MeetingFormat]: schedule?.meeting_format || MeetingFormats.LessonSpace,
      [FormFieldNames.Address1]: schedule?.address_1,
      [FormFieldNames.Address2]: schedule?.address_2,
      [FormFieldNames.City]: schedule?.city,
      [FormFieldNames.State]: schedule?.state,
      [FormFieldNames.Zip]: schedule?.zip,
      [FormFieldNames.Link]: link,
      [FormFieldNames.ScheduleNote]: schedule?.note,
      [FormFieldNames.MaxSessions]: schedule?.max_sessions,
      [FormFieldNames.Duration]: schedule?.max_duration,
      [FormFieldNames.SendReportsTo]: Array.isArray(schedule?.additional_emails)
        ? schedule?.additional_emails.join(", ")
        : schedule?.additional_emails,
      [FormFieldNames.NotifyAboutUpcoming]: Boolean(schedule?.notify_parent),
      [FormFieldNames.Rate]: schedule?.school_rate,
    }
  }, [schedules, initialSchoolComplete, schools, initialEducatableComplete, educatable])

  return {
    initialValues,
    loading: schoolLoading || educatableLoading,
  }
}

export const useEditInitialValues = schedules => {
  const { schools, educatables: educatable } = useContext(DataContext)

  const multipleValues = useMemo(() => {
    const values = {
      [FormFieldNames.School]: false,
      [FormFieldNames.Educatable]: false,
      [FormFieldNames.Subject]: false,
      [FormFieldNames.StartOn]: false,
      [FormFieldNames.EndOn]: false,
      [FormFieldNames.MeetingFormat]: false,
      [FormFieldNames.Address1]: false,
      [FormFieldNames.Address2]: false,
      [FormFieldNames.City]: false,
      [FormFieldNames.State]: false,
      [FormFieldNames.Zip]: false,
      [FormFieldNames.Link]: false,
      [FormFieldNames.ScheduleNote]: false,
      [FormFieldNames.MaxSessions]: false,
      [FormFieldNames.Duration]: false,
      [FormFieldNames.SendReportsTo]: false,
      [FormFieldNames.NotifyAboutUpcoming]: false,
      [FormFieldNames.Rate]: false,
    }
    if (schedules.length <= 1) return values

    const getLink = schedule => {
      let link = ""
      switch (schedule.meeting_format) {
        case MeetingFormats.Online:
          link = schedule.meeting_info
          break
      }
      return link
    }

    const getEmail = schedule =>
      Array.isArray(schedule.additional_emails)
        ? schedule?.additional_emails.join(", ")
        : schedule?.additional_emails || ""

    schedules.reduce((sch1, sch2) => {
      if (!values[FormFieldNames.School]) values[FormFieldNames.School] = sch1.school.id !== sch2.school.id
      if (!values[FormFieldNames.Educatable])
        values[FormFieldNames.Educatable] = sch1.educatable.id !== sch2.educatable.id
      if (!values[FormFieldNames.Subject]) values[FormFieldNames.Subject] = sch1.program.id !== sch2.program.id
      if (!values[FormFieldNames.StartOn]) values[FormFieldNames.StartOn] = sch1.start_on !== sch2.start_on
      if (!values[FormFieldNames.EndOn]) values[FormFieldNames.EndOn] = sch1.end_on !== sch2.end_on
      if (!values[FormFieldNames.MeetingFormat])
        values[FormFieldNames.MeetingFormat] = sch1.meeting_format !== sch2.meeting_format
      if (!values[FormFieldNames.Address1]) values[FormFieldNames.Address1] = sch1.address_1 !== sch2.address_1
      if (!values[FormFieldNames.Address2]) values[FormFieldNames.Address2] = sch1.address_2 !== sch2.address_2
      if (!values[FormFieldNames.City]) values[FormFieldNames.City] = sch1.city !== sch2.city
      if (!values[FormFieldNames.State]) values[FormFieldNames.State] = sch1.state !== sch2.state
      if (!values[FormFieldNames.Zip]) values[FormFieldNames.Zip] = sch1.zip !== sch2.zip
      if (!values[FormFieldNames.Link]) values[FormFieldNames.Link] = getLink(sch1) !== getLink(sch2)
      if (!values[FormFieldNames.ScheduleNote]) values[FormFieldNames.ScheduleNote] = sch1.note !== sch2.note
      if (!values[FormFieldNames.MaxSessions])
        values[FormFieldNames.MaxSessions] = sch1.max_sessions !== sch2.max_sessions
      if (!values[FormFieldNames.Duration]) values[FormFieldNames.Duration] = sch1.max_duration !== sch2.max_duration
      if (!values[FormFieldNames.SendReportsTo])
        values[FormFieldNames.SendReportsTo] = getEmail(sch1) !== getEmail(sch2)
      if (!values[FormFieldNames.NotifyAboutUpcoming])
        values[FormFieldNames.NotifyAboutUpcoming] = sch1.notify_parent !== sch2.notify_parent
      if (!values[FormFieldNames.Rate]) values[FormFieldNames.Rate] = sch1.school_rate !== sch2.school_rate

      return sch2
    })
    return values
  }, [schedules])

  const initialValues = useMemo(() => {
    const [schedule = {}] = schedules
    let link = ""
    if (!multipleValues[FormFieldNames.Link]) {
      switch (schedule.meeting_format) {
        case MeetingFormats.Online:
          link = schedule.meeting_info
          break
      }
    }
    return {
      [FormFieldNames.School]: multipleValues[FormFieldNames.School] ? void 0 : schedule?.school ?? schools?.[0],
      [FormFieldNames.Educatable]: multipleValues[FormFieldNames.Educatable]
        ? void 0
        : schedule?.educatable ?? educatable?.[0],
      [FormFieldNames.Subject]: multipleValues[FormFieldNames.Subject] ? void 0 : schedule?.program,
      [FormFieldNames.StartOn]: multipleValues[FormFieldNames.StartOn]
        ? null
        : schedule?.start_on
        ? new Date(schedule?.start_on)
        : null,
      [FormFieldNames.EndOn]: multipleValues[FormFieldNames.EndOn]
        ? null
        : schedule?.end_on
        ? new Date(schedule?.end_on)
        : null,
      [FormFieldNames.MeetingFormat]: multipleValues[FormFieldNames.MeetingFormat]
        ? null
        : schedule?.meeting_format || MeetingFormats.LessonSpace,
      [FormFieldNames.Address1]: multipleValues[FormFieldNames.Address1] ? null : schedule?.address_1 || null,
      [FormFieldNames.Address2]: multipleValues[FormFieldNames.Address2] ? null : schedule?.address_2 || null,
      [FormFieldNames.City]: multipleValues[FormFieldNames.City] ? null : schedule?.city || null,
      [FormFieldNames.State]: multipleValues[FormFieldNames.State] ? null : schedule?.state || null,
      [FormFieldNames.Zip]: multipleValues[FormFieldNames.Zip] ? null : schedule?.zip || null,
      [FormFieldNames.Link]: multipleValues[FormFieldNames.Link] ? null : link || null,
      [FormFieldNames.ScheduleNote]: multipleValues[FormFieldNames.ScheduleNote] ? null : schedule?.note || null,
      [FormFieldNames.MaxSessions]: multipleValues[FormFieldNames.MaxSessions] ? null : schedule?.max_sessions ?? null,
      [FormFieldNames.Duration]: multipleValues[FormFieldNames.Duration] ? null : schedule?.max_duration ?? null,
      [FormFieldNames.SendReportsTo]: multipleValues[FormFieldNames.SendReportsTo]
        ? null
        : Array.isArray(schedule?.additional_emails)
        ? schedule?.additional_emails.join(", ")
        : schedule?.additional_emails || "",
      [FormFieldNames.NotifyAboutUpcoming]: multipleValues[FormFieldNames.NotifyAboutUpcoming]
        ? false
        : schedule?.notify_parent || false,
      [FormFieldNames.Rate]: multipleValues[FormFieldNames.Rate] ? null : schedule?.school_rate ?? null,
    }
  }, [schedules, multipleValues, schools, educatable])

  return {
    initialValues,
    multipleValues,
  }
}

export const mapFormFieldsToRequestFields = data => {
  const result = {}
  for (const key of Object.keys(data)) {
    const dataValue = data[key]

    // Skip undefined values (set in initialValues hook)
    // Still allows empty string("") values to be sent to the backend
    if (dataValue === undefined || dataValue === null) continue

    switch (key) {
      case FormFieldNames.Educatable: {
        if (dataValue?.value) result.educatable_id = parseInt(dataValue.value, 10)
        break
      }
      case FormFieldNames.Subject: {
        if (dataValue?.value || dataValue?.id)
          result.default_program_id = parseInt(dataValue?.value || dataValue?.id, 10)
        break
      }
      case FormFieldNames.School: {
        if (dataValue?.value || dataValue?.id) result.school_id = parseInt(dataValue?.value || dataValue?.id, 10)
        break
      }
      case FormFieldNames.MaxSessions:
      case FormFieldNames.Rate: {
        result[key] = dataValue === void 0 ? null : parseFloat(dataValue) || null
        break
      }
      case FormFieldNames.Duration:
      case FormFieldNames.SendReportsTo: {
        result[key] = dataValue || null
        break
      }

      case FormFieldNames.ScheduleNote: {
        result[key] = dataValue || ""
        break
      }

      case FormFieldNames.Link: {
        if (data[FormFieldNames.MeetingFormat] === MeetingFormats.Online) result[key] = dataValue
        break
      }
      case FormFieldNames.Address1:
      case FormFieldNames.Address2:
      case FormFieldNames.City:
      case FormFieldNames.State:
      case FormFieldNames.Zip: {
        if (data[FormFieldNames.MeetingFormat] === MeetingFormats.Offline) result[key] = dataValue
        break
      }

      case FormFieldNames.MeetingFormat:
      case FormFieldNames.NotifyAboutUpcoming: {
        result[key] = dataValue
        break
      }
      case FormFieldNames.EndOn:
      case FormFieldNames.StartOn: {
        result[key] = formatDate(dataValue)
        break
      }
    }
  }

  return result
}

export const validateScheduleForm =
  ({ type }) =>
  values => {
    const errors = {}

    if (type === "create") {
      if (!values[FormFieldNames.School]) {
        errors[FormFieldNames.School] = "This field is required"
      }

      if (!values[FormFieldNames.Educatable]) {
        errors[FormFieldNames.Educatable] = "This field is required"
      }
    }

    if (!values[FormFieldNames.Subject]) {
      errors[FormFieldNames.Subject] = "This field is required"
    }

    if (!values[FormFieldNames.StartOn]) {
      errors[FormFieldNames.StartOn] = "This field is required"
    }

    if (!values[FormFieldNames.Rate]) {
      errors[FormFieldNames.Rate] = "This field is required"
    }

    if (values[FormFieldNames.Rate] && isNaN(parseFloat(values[FormFieldNames.Rate]))) {
      errors[FormFieldNames.Rate] = "This field value must be numeric"
    }

    if (values[FormFieldNames.MaxSessions]) {
      const maxSessions = parseInt(values[FormFieldNames.MaxSessions], 10)
      if (isNaN(maxSessions)) {
        errors[FormFieldNames.MaxSessions] = "This field value must be numeric"
      } else if (maxSessions < 0) {
        errors[FormFieldNames.MaxSessions] = "This field value must be positive"
      }
    }

    return {
      ...errors,
      ...defaultLocationValidation(values),
    }
  }

export const multipleValidateScheduleForm = values => {
  const errors = {}

  if (values[FormFieldNames.Rate] && isNaN(parseFloat(values[FormFieldNames.Rate]))) {
    errors[FormFieldNames.Rate] = "This field value must be numeric"
  }

  if (values[FormFieldNames.MaxSessions]) {
    const maxSessions = parseInt(values[FormFieldNames.MaxSessions], 10)
    if (isNaN(maxSessions)) {
      errors[FormFieldNames.MaxSessions] = "This field value must be numeric"
    } else if (maxSessions < 0) {
      errors[FormFieldNames.MaxSessions] = "This field value must be positive"
    }
  }

  return errors
}
