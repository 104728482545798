import { MeetingFormats } from "@/constants"
import React, { useState } from "react"
import { checkUrl, getScheduleOfflineAddress } from "../../helpers"
import { LessonspacePreviewModal } from "./lessonspace_preview_modal"

export const Location = ({ schedule, role }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  let url

  const handlePreview = scheduleId => {
    window.open(`/${role}/dashboard/classroom/${scheduleId}`, "_blank")
    setShowPreviewModal(false)
  }

  switch (schedule.meeting_format) {
    case MeetingFormats.Offline:
      return <span>{getScheduleOfflineAddress(schedule)}</span>

    case MeetingFormats.Online: {
      url = schedule.meeting_info ? checkUrl(schedule.meeting_info) : ""
      if (url) {
        return (
          <>
            <span className="mr-1">Online</span>
            <a href={schedule.meeting_info} target="_blank" rel="noreferrer">
              {schedule.meeting_info}
            </a>
          </>
        )
      }
      return <span>Online</span>
    }

    case MeetingFormats.LessonSpace: {
      url = schedule.meeting_info ? checkUrl(schedule.meeting_info) : ""

      if (schedule?.id) {
        return (
          <>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setShowPreviewModal(true)
              }}
            >
              Lessonspace
            </a>
            <LessonspacePreviewModal
              show={showPreviewModal}
              onClose={() => setShowPreviewModal(false)}
              onPreview={handlePreview}
              scheduleId={schedule.id}
            />
          </>
        )
      }
      if (url) {
        return (
          <>
            <span className="mr-1">Lessonspace</span>
            <a href={schedule.meeting_info} target="_blank" rel="noreferrer">
              {schedule.meeting_info}
            </a>
          </>
        )
      }

      return <span>Lessonspace</span>
    }

    default:
      return <span>—</span>
  }
}
