import { addDays, format, isSameDay, parseISO, startOfToday } from "date-fns"
import moment from "moment"

/**
 * Date formatting and parsing utilities for the application.
 * This module provides a set of functions to handle date/time operations consistently
 * across the application, with special handling for timezone-sensitive operations.
 */

/**
 * Formats a date to UTC while preserving the calendar date.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DD"] - The desired output format
 * @returns {string|null} Formatted date string in UTC or null if input is falsy
 */
export const formatDate = (date, format = "YYYY-MM-DD") => {
  if (!date) return null
  const localDate = moment(date)
  const utcDate = moment.utc().year(localDate.year()).month(localDate.month()).date(localDate.date())
  return utcDate.format(format)
}

/**
 * Parses a UTC date string to a local Date object while preserving the calendar date.
 * @param {string} str - The UTC date string to parse
 * @returns {Date|null} JavaScript Date object in local time or null if input is falsy
 */
export const parseDate = str => {
  if (!str) return null
  const utcDate = moment.utc(str)
  const localDate = moment().year(utcDate.year()).month(utcDate.month()).date(utcDate.date())
  return localDate.toDate()
}

/**
 * Formats a time string according to the specified format.
 * @param {string} time - The time string to format
 * @param {string} [format="h:mm A"] - The desired output format
 * @returns {string|null} Formatted time string or null if input is invalid
 */
export const formatTime = (time, format = "h:mm A") => {
  if (!time) return null
  const m = moment(time, format)
  return m.isValid() ? m.format(format) : null
}

/**
 * Parses a time string to a Date object.
 * @param {string} str - The time string to parse
 * @param {string} [format="HH:mm"] - The format of the input time string
 * @returns {Date|null} JavaScript Date object or null if input is falsy
 */
export const parseTime = (str, format = "HH:mm") => (str ? moment(str, format).toDate() : null)

/**
 * Formats a date with time information.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DDTHH:mm"] - The desired output format
 * @returns {string|null} Formatted date-time string or null if input is falsy
 */
export const formatDateTime = (date, format = "YYYY-MM-DDTHH:mm") => (date ? moment(date).format(format) : null)

/**
 * Gets the start of week for a given date.
 * @param {Date|string} date - The date to get the start of week for
 * @param {string} [format="YYYY-MM-DD"] - The desired output format
 * @returns {string} Formatted date string for the start of week
 */
export const getStartOfWeek = (date, format = "YYYY-MM-DD") => moment(date).startOf("week").format(format)

/**
 * Gets the end of week for a given date.
 * @param {Date|string} date - The date to get the end of week for
 * @param {string} [format="YYYY-MM-DD"] - The desired output format
 * @returns {string} Formatted date string for the end of week
 */
export const getEndOfWeek = (date, format = "YYYY-MM-DD") => moment(date).endOf("week").format(format)

/**
 * Formats a date while preserving timezone information.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DD"] - The desired output format
 * @returns {string|null} Formatted date string or null if input is falsy
 */
export const formatDateKeepZone = (date, format = "YYYY-MM-DD") => (date ? moment.parseZone(date).format(format) : null)

/**
 * Formats a date-time while preserving timezone information.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DDTHH:mm"] - The desired output format
 * @returns {string|null} Formatted date-time string or null if input is falsy
 */
export const formatDateTimeKeepZone = (date, format = "YYYY-MM-DDTHH:mm") =>
  date ? moment.parseZone(date).format(format) : null

/**
 * Formats the start of day while preserving timezone information.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DDTHH:mm"] - The desired output format
 * @returns {string|undefined} Formatted date-time string or undefined if input is falsy
 */
export const formatStartOfDayKeepZone = (date, format = "YYYY-MM-DDTHH:mm") =>
  date ? moment.parseZone(date).startOf("day").format(format) : undefined

/**
 * Formats the end of day while preserving timezone information.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DDTHH:mm"] - The desired output format
 * @returns {string|null} Formatted date-time string or null if input is falsy
 */
export const formatEndOfDayKeepZone = (date, format = "YYYY-MM-DDTHH:mm") =>
  date ? moment.parseZone(date).endOf("day").format(format) : null

/**
 * Gets the day of week (0-6) for a given date.
 * @param {Date|string} d - The date to get the day of week for
 * @returns {number|null} Day of week (0-6) or null if input is falsy
 */
export const dateToDayOfWeek = d => (d ? moment(d).day() : null)

/**
 * Checks if a value is a valid Date object.
 * @param {any} value - The value to check
 * @returns {boolean} True if value is a valid Date object
 */
export const checkDateIsValid = value => value instanceof Date && !isNaN(value.valueOf())

/**
 * Converts minutes to hours and remaining minutes.
 * @param {number} value - The number of minutes to convert
 * @returns {[number, number]} Array containing [hours, minutes]
 */
export const minutesToHours = value => {
  const hours = Math.floor(value / 60)
  const minutes = Math.round(value - hours * 60) % 60
  return [hours, minutes]
}

/**
 * Gets an array of month objects for the current year.
 * @returns {Array<{name: string, date: string, label: string}>} Array of month objects
 */
export const getMonths = () => {
  const currentYear = new Date().getFullYear()
  return [
    { name: "January", date: `${currentYear}-01-01`, label: `January` },
    { name: "February", date: `${currentYear}-02-01`, label: `February` },
    { name: "March", date: `${currentYear}-03-01`, label: `March` },
    { name: "April", date: `${currentYear}-04-01`, label: `April` },
    { name: "May", date: `${currentYear}-05-01`, label: `May` },
    { name: "June", date: `${currentYear}-06-01`, label: `June` },
    { name: "July", date: `${currentYear}-07-01`, label: `July` },
    { name: "August", date: `${currentYear}-08-01`, label: `August` },
    { name: "September", date: `${currentYear}-09-01`, label: `September` },
    { name: "October", date: `${currentYear}-10-01`, label: `October` },
    { name: "November", date: `${currentYear}-11-01`, label: `November` },
    { name: "December", date: `${currentYear}-12-01`, label: `December` },
  ]
}

/**
 * Gets the ordinal suffix for a day number (e.g., "st" for 1, "nd" for 2).
 * @param {number} day - The day number
 * @returns {string} The ordinal suffix
 */
export function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th"
  switch (day % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

/**
 * Formats a date into a string with month name and day with ordinal suffix.
 * @param {Date} date - The date to format
 * @returns {string} Formatted date string (e.g., "January, 1st")
 */
export function formatDateString(date) {
  const options = { month: "long" }
  const month = date.toLocaleDateString("en-US", options)
  const day = date.getDate()
  const suffix = getOrdinalSuffix(day)
  return `${month}, ${day}${suffix}`
}

/**
 * Formats a given ISO date string into a human-readable format.
 * - "Today at h:mm a"
 * - "Tomorrow at h:mm a"
 * - "MMMM d, yyyy 'at' h:mm a" for other dates
 *
 * @param {string|Date} unformattedDate - The ISO date string or Date object to format.
 * @returns {string|null} - The formatted date string.
 */
export const formatCustomDate = unformattedDate => {
  if (!unformattedDate) return null
  const date = typeof unformattedDate === "string" ? parseISO(unformattedDate) : unformattedDate
  if (isNaN(date)) return "Invalid date"

  const today = startOfToday()
  const tomorrow = addDays(today, 1)

  if (isSameDay(date, today)) {
    return `Today at ${format(date, "h:mm a")}`
  } else if (isSameDay(date, tomorrow)) {
    return `Tomorrow at ${format(date, "h:mm a")}`
  } else {
    return format(date, "MMMM d, yyyy 'at' h:mm a")
  }
}

/**
 * Formats a date for the date range component, preserving exact selected date.
 * @param {Date|string} date - The date to format
 * @param {string} [format="YYYY-MM-DD"] - The desired output format
 * @returns {string|null} Formatted date string or null if input is falsy
 */
export const formatRangeDate = (date, format = "YYYY-MM-DD") => {
  if (!date) return null
  return moment(date).format(format)
}

/**
 * Parses a date string for the date range component, preserving exact date.
 * @param {string} str - The date string to parse
 * @returns {Date|null} JavaScript Date object or null if input is falsy
 */
export const parseRangeDate = str => {
  if (!str) return null
  return moment(str, "YYYY-MM-DD").toDate()
}
